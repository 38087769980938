.shared-image-page {
    max-width: 300px;
    margin: 0 auto;
}

.shared-image-page .user-avatar{
    cursor: pointer;
}

.shared-image-page .username {
    display: inline-block;
    max-width: 200px;
    margin: 0 auto;
    position: relative;
    bottom: 25px;
    background-color: rgba(243, 237, 246, 0.86);
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    cursor: pointer;
}

.shared-image-page .shared {
    margin-bottom: 10px;
}

.shared-image-page hr {
    margin: 30px 0;
}

.shared-image-page .company-logo {
    margin-top: -20px;
}

.shared-image-page .app {
    margin: 0 auto;
}

.shared-image-page .icon {
    height: 56px;
    width: 56px;
    border-radius: 8px;
}

.shared-image-page .app-store-link-apple {
    margin-top: 20px;
}

.shared-image-page .app-store-link-google {
    margin-top: 16px;
}

.shared-image-page .company-logo.medium img {
    height: 72px;
}

.shared-image-page .tooltip {
    display: inline-block;
    margin-left: 2px;
    cursor: pointer;
}
